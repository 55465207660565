import { useEffect, useState } from 'react';
import Speech from 'react-speech'
import './App.css';

const alphabet = {
  a: 'ej',
  b: 'bi',
  c: 'si',
  d: 'di',
  e: 'i',
  f: 'ef',
  g: 'dzi',
  h: 'ejdz',
  i: 'aj',
  j: 'dzej',
  k: 'kej',
  l: 'el',
  m: 'em',
  n: 'en',
  o: 'ou',
  p: 'pi',
  q: 'kju',
  r: 'ar',
  s: 'es',
  t: 'ti',
  u: 'ju',
  v: 'vi',
  w: 'dabl ju',
  x: 'eks',
  y: 'uaj',
  z: 'zi',
}
const alphabetReverse = {
  ej: 'a',
  bi: 'b',
  si: 'c',
  di: 'd',
  e: 'i',
  i: 'e',
  ef: 'f',
  dzi: 'g',
  ejdz: 'h',
  aj: 'i',
  dzej: 'j',
  kej: 'k',
  el: 'l',
  em: 'm',
  en: 'n',
  ou: 'o',
  pi: 'p',
  kju: 'q',
  ar: 'r',
  es: 's',
  ti: 't',
  ju: 'u',
  vi: 'v',
  ["dabl-ju"]: 'w',
  eks: 'x',
  uaj: 'y',
  zi: 'z'
}

function App() {
  const [word, setWord] = useState('')
  const [spelledWord, setSpellerWord] = useState([])

  useEffect(() => {
    if (word.length === 0) {
      setSpellerWord([])
    }
    else if (word.length > 0) {
      const letters = word.trim().toLowerCase().split('').map(letter => alphabet[letter])

      setSpellerWord(letters)
    }
  }, [word])

  return (
    <div className="App">
      <div className="wrapper">
        <h1>SPELLER</h1>
        <h2>Free for everyone</h2>
        <q>Just write a word that you wanna spell</q>
        <div className="main">
          <div className="input">
            <input type="text" placeholder='type here...' value={word} onChange={(e) => setWord(e.target.value)} />
          </div>
          <div className="letterContainer">
            {
              spelledWord.map((letter, idx) => {
                return (
                  <div key={idx}>
                    <Speech
                      textAsButton={true}
                      displayText={letter}
                      text={alphabetReverse[letter]}
                      voice="Google UK English Female"
                      rate="1"
                      volume="1"
                      pitch="1"
                      className='letter'
                    />
                  </div>
                )

              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
